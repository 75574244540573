import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { CarDetails, CarPhoto } from 'src/app/core/models/car.model';
import { CarService } from 'src/app/core/services/car.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { VAT } from '../car-shop-card/car-shop-card.component';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-view-car-details-modal',
  templateUrl: './view-car-details-modal.component.html',
  styleUrls: ['./view-car-details-modal.component.scss']
})

export class ViewCarDetailsModalComponent implements OnInit {
  loading = new BehaviorSubject<boolean>(true);
  carId = this.data.carId;
  car: CarDetails | undefined;
  equipments: string[] = [];
  images: CarPhoto[] = [];
  vatStatus = VAT;

  constructor(private carService: CarService,
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: { carId: string },
    private snackbar: SnackbarService) { }

  ngOnInit() {
    forkJoin({
      car: this.carService.getCarDetails(this.carId),
      carPhotos: this.carService.getCarPhotos(this.carId),
      carMasterEquipments: this.carService.getCarMasterEquipments(this.carId),
      carC2VEquipments: this.carService.getCarC2VEquipments(this.carId),
    }).subscribe(resp => {
      this.car = resp.car;


      if (resp.carMasterEquipments.optionalEquipment) {
        this.utilsService.addEquipmentToArray(resp.carMasterEquipments.optionalEquipment, this.equipments);
        this.utilsService.addEquipmentToArray(resp.carMasterEquipments.standardEquipment, this.equipments);
        this.utilsService.addEquipmentToArray(resp.carMasterEquipments.packEquipment, this.equipments);
      }

      //add car custom eq to list
      this.equipments = [...this.equipments, ...resp.carC2VEquipments.customEquipment.map((c: string) => this.utilsService.splitWordByUnderline(c))];


      this.images = resp.carPhotos;
      this.images = this.images.length > 0 ? this.images : [{
        originalPhoto: resp.car.profilePhoto,
        name: 'Default photo',
      }];

      this.loading.next(false);
    });
  }

  downloadFile(docId: string) {
    this.carService.downloadCarDocument(this.car!.carMainInfoId, docId).subscribe({
      next: resp => {
        window.open(resp, "_blank");
      },
      error: err => {
        this.snackbar.negativeSentiment(err.error);
      }
    });
  }
}
