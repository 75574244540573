<div class="flex flex-col p-6 gap-6 text-sm sm:text-base relative">
  <div class="absolute top-0 right-1">
    <fa-icon id="close-modal-btn" data-cy="close-modal-btn" class="cursor-pointer" [icon]="['far','xmark']"
      (click)="close()"></fa-icon>
  </div>

  <div class="sm:text-xl font-bold">Make counter offer</div>

  <div class="flex justify-between">
    <p>Selling price</p>

    <p>€{{ shopPrice | number}}</p>
  </div>

  <div class="flex justify-between">
    <p>Last offered price</p>

    <p>€{{latestOffer | number}}</p>
  </div>

  <div class="flex flex-col xs:flex-row justify-between items-center">
    <p>New offer value €</p>

    <app-input-number componentId="new-offer-value" [control]="valueControl"
      placeholder="Insert new offered price €"></app-input-number>
  </div>

  <div class="flex flex-col xs:flex-row justify-between items-center" *ngIf="isExpired()">
    <p>New offer expiration date</p>

    <app-datepicker [control]="expiresOnControl" [min]="today"></app-datepicker>
  </div>


  <app-blue-btn id="make-counter-offer" (clickEmitter)="makeOffer()" class="self-end">Create counter
    offer</app-blue-btn>
</div>
