<div #navbar class="bg-black-darkChip fixed top-0 z-10 w-full">
  <div class="px-4 xl:px-6 2xl:px-24 justify-between text-blue-headerBg hidden lg:flex">
    <div class="flex items-center gap-5">
      <img class="w-[6.25rem] cursor-pointer" (click)="goToHome();trackGA('logo','home','nav_bar','click',1)"
        src="./assets/logos/logos updated nov 2023-62.svg" alt="">

      <div class="flex items-center">
        <p class="cursor-pointer px-9 py-4 hover:bg-black-darkChipHover hover:text-white"
          [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'shop'}"
          (click)="goToShop();trackGA('shop-btn','shop','nav_bar','click',2)">SHOP</p>

        <p class="cursor-pointer px-5 xl:px-9 py-4 hover:bg-black-darkChipHover hover:text-white"
          [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'closedSales'}"
          (click)="goToClosedSales();trackGA('closed-sale-btn','closed-sale','nav_bar','click',3)">CLOSED
          SALES
        </p>

        <!-- <p class="cursor-pointer px-5 xl:px-9 py-4 hover:bg-black-darkChipHover hover:text-white">CARS2VIEW</p> -->
      </div>
    </div>

    <div class="flex items-center">
      <div class="flex gap-3 items-center px-9 py-4 hover:bg-black-darkChipHover hover:text-white cursor-pointer"
        [matMenuTriggerFor]="offersMenu">
        <div class="rounded-[100%] w-[6px] h-[6px] bg-orange"></div>
        <p>OFFERS <span class="ml-2"><fa-icon [icon]="['far','chevron-down']"></fa-icon></span></p>
      </div>

      <mat-menu #offersMenu="matMenu">
        <div class="p-4 flex flex-col gap-4" *ngIf="!(loading | async);else placeholder">
          <div *ngIf="offers.accepted.length > 0" class="flex flex-col gap-4">
            <p class="font-bold">Offers accepted</p>

            <div class="flex gap-4" *ngFor="let offer of offers.accepted">
              <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active"
                class="w-full max-w-[5.6rem] flex">
                <img
                  [src]="offer.carDetails.profilePhoto && offer.carDetails.profilePhoto.length > 0 ? offer.carDetails.profilePhoto : 'assets/photos/car-placeholder.png'"
                  class="object-cover w-full" alt="">
              </a>

              <div class="flex flex-col gap-1">
                <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active">
                  <p class="text-sm">{{offer.carDetails.make}} {{offer.carDetails.model}}, {{offer.carDetails.variant}}
                  </p>
                </a>

                <p class="text-green font-bold">€{{offer.latestOffer | number}}</p>

                <p class="text-white-greyText text-sm">{{offer.carDetails.vatStatus ? vatStatus.ExVAT :
                  vatStatus.IncludedVAT}}</p>
              </div>
            </div>
          </div>

          <div *ngIf="offers.newFromC2C.length > 0" class="flex flex-col gap-4">
            <p class="font-bold">New offers from Cars2click</p>

            <div class="flex gap-4" *ngFor="let offer of offers.newFromC2C">
              <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active"
                class="w-full max-w-[5.6rem] flex">
                <img
                  [src]="offer.carDetails.profilePhoto && offer.carDetails.profilePhoto.length > 0 ? offer.carDetails.profilePhoto : 'assets/photos/car-placeholder.png'"
                  class="object-cover w-full" alt="">
              </a>

              <div class="flex flex-col gap-1">
                <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active">
                  <p class="text-sm">{{offer.carDetails.make}} {{offer.carDetails.model}}, {{offer.carDetails.variant}}
                  </p>
                </a>

                <p class="text-red font-bold">€{{offer.latestOffer | number}}</p>

                <p class="text-white-greyText text-sm">{{offer.carDetails.vatStatus ? vatStatus.ExVAT :
                  vatStatus.IncludedVAT}}</p>
              </div>
            </div>
          </div>

          <div *ngIf="offers.countered.length > 0" class="flex flex-col gap-4">
            <p class="font-bold">Offers countered</p>

            <div class="flex gap-4" *ngFor="let offer of offers.countered">
              <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active"
                class="w-full max-w-[5.6rem] flex">
                <img
                  [src]="offer.carDetails.profilePhoto && offer.carDetails.profilePhoto.length > 0 ? offer.carDetails.profilePhoto : 'assets/photos/car-placeholder.png'"
                  class="object-cover w-full" alt="">
              </a>

              <div class="flex flex-col gap-1">
                <a [routerLink]="'/car/' + offer.carDetails.carMainInfoId" routerLinkActive="active">
                  <p class="text-sm">{{offer.carDetails.make}} {{offer.carDetails.model}}, {{offer.carDetails.variant}}
                  </p>
                </a>

                <p class="text-orange font-bold">€{{offer.latestOffer | number}}</p>

                <p class="text-white-greyText text-sm">{{offer.carDetails.vatStatus ? vatStatus.ExVAT :
                  vatStatus.IncludedVAT}}</p>
              </div>
            </div>
          </div>

          <app-green-btn (clickEmitter)="goToOffers();trackGA('offer-btn','offers','nav_bar','click',4)">GO TO
            OFFERS</app-green-btn>
        </div>
      </mat-menu>

      <div class="flex gap-3 items-center px-9 py-4 hover:bg-black-darkChipHover hover:text-white cursor-pointer"
        [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'cart'}" [matMenuTriggerFor]="cartMenu"
        (click)="trackGA('cart-dropdown','home','nav_bar','click',5)">
        <div class="rounded-[100%] py-1 px-[0.625rem] text-xs bg-[#F33A00] text-white">{{cartCars.length| number}}</div>
        <p>CART <span class="ml-2"><fa-icon [icon]="['far','chevron-down']"></fa-icon></span></p>
      </div>

      <mat-menu #cartMenu="matMenu" class="cartMatManu">
        <div class="p-4 flex flex-col gap-4 overflow-hidden"
          *ngIf="!(loading | async) && !(loadingCart | async);else placeholder">
          <div class="flex gap-4 flex-col overflow-hidden max-h-80 overflow-y-scroll box-content w-full pr-12">
            <div class="flex gap-4 flex-col" *ngFor="let car of cartCars">
              <div class="flex justify-between items-center">
                <a class="font-bold" [routerLink]="'/car/' + car!.carMainInfoId" routerLinkActive="active">
                  {{car.make}} {{car.model}}, {{car.variant}}
                </a>

                <fa-icon (click)="removeFromCart(car.carMainInfoId!)"
                  class="cursor-pointer fa-icon-div text-white-greyText transition duration-150 hover:scale-105 ease-in-out"
                  [icon]="['fas','trash-can']"></fa-icon>
              </div>

              <div class="flex gap-4">
                <a [routerLink]="'/car/' + car!.carMainInfoId" routerLinkActive="active"
                  class="w-full max-w-[5.6rem] flex gap-4 cursor-pointer">
                  <img
                    [src]="car.profilePhoto && car.profilePhoto.length > 0 ? car.profilePhoto : 'assets/photos/car-placeholder.png'"
                    class="object-cover w-full" alt="">
                </a>

                <div class="flex flex-col gap-1">
                  <div class="flex flex-wrap gap-2 text-sm items-center">
                    <p>{{car?.mileage | number}} KM</p>
                    <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-300"></div>
                    <p>{{car?.manufactureYear}}</p>
                    <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-300"></div>
                    <p>{{car?.gearbox}}</p>
                    <div class="rounded-[100%] w-[6px] h-[6px] bg-blue-300"></div>
                    <p>{{car?.fuelType}}</p>
                  </div>

                  <p class="text-blue-ctaBlue font-bold">€{{( car.carPricing.offerDetails.offeredPrice ?
                    car.carPricing.offerDetails.offeredPrice : car.carPricing.shopPrice )| number}}</p>

                  <p class="text-white-greyText text-sm">{{car.vatStatus ? vatStatus.ExVAT :
                    vatStatus.IncludedVAT}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex text-sm gap-4" [ngClass]="{'pt-4 boxShadowCart' : cartCars.length > 0}">
            <div class="flex justify-center flex-col gap-1 w-1/3">
              <p class="font-bold">Total</p>
              <p>{{cartCars.length}} cars</p>
            </div>

            <div class="pl-6 border-l border-l-white-lines">
              <p class="text-xl text-blue-ctaBlue font-bold">€{{getTotalCartSum() | number}}</p>

              <p>(contains ex VAT & non refundable VAT)</p>

              <p class="text-green">including transport (ex. VAT)</p>
            </div>
          </div>

          <app-green-btn (clickEmitter)="goToCart();trackGA('see-cart-btn','see-cart','nav_bar','click',6)">SEE
            CART</app-green-btn>
        </div>
      </mat-menu>

      <p class="cursor-pointer px-9 py-4 hover:bg-black-darkChipHover hover:text-white"
        [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'account'}"
        (click)="trackGA('profile-dropdown','profile','nav_bar','click',7)" [matMenuTriggerFor]="profileMenu">
        PROFILE

        <span class="ml-2"><fa-icon [icon]="['far','chevron-down']"></fa-icon></span>
      </p>

      <mat-menu #profileMenu="matMenu" class="!bg-black-darkChip">
        <div class="text-blue-headerBg">
          <p class="hover:bg-black-darkChipHover hover:text-white cursor-pointer px-4 py-2"
            (click)="goTo('my-account');trackGA('account-btn-dropdown','account','nav_bar','click',13)">
            MY ACCOUNT
          </p>


          <p class="hover:bg-black-darkChipHover hover:text-white cursor-pointer px-4 py-2"
            (click)="goTo('my-orders');trackGA('orders-btn-dropdown','orders','nav_bar','click',8)">
            MY ORDERS
          </p>

          <p class="hover:bg-black-darkChipHover hover:text-white cursor-pointer px-4 py-2"
            (click)="goTo('my-offers');trackGA('offers-btn-dropdown','offers','nav_bar','click',9)">
            OFFERS
          </p>

          <p class="hover:bg-black-darkChipHover hover:text-white cursor-pointer px-4 py-2"
            (click)="goTo('my-favorites');trackGA('favorites-btn-dropdown','favorites','nav_bar','click',10)">
            FAVORITES
          </p>

          <p class="hover:bg-black-darkChipHover hover:text-white cursor-pointer px-4 py-2"
            (click)="goTo('saved-searches');trackGA('saved-searches-btn-dropdown','saved-searches','nav_bar','click',11)">
            SAVED SEARCH
          </p>

          <p class="hover:bg-black-darkChipHover hover:text-white cursor-pointer px-4 py-2"
            (click)="logout();trackGA('logout-btn-dropdown','logout','nav_bar','click',12)">
            LOGOUT
          </p>
        </div>
      </mat-menu>


      <!-- <div class="cursor-pointer px-9 py-4 flex gap-3 items-center hover:bg-black-darkChipHover hover:text-white">
      <p>EN</p>
      <fa-icon [icon]="['far','chevron-down']"></fa-icon>
    </div> -->

      <!-- <p class="cursor-pointer px-9 py-4 hover:bg-black-darkChipHover hover:text-white" (click)="logout()">LOGOUT</p> -->
    </div>
  </div>

  <div class="lg:hidden py-3 bg-black-darkChip">
    <div class="container flex items-center justify-between">
      <div>
        <img class="w-[11.25rem] cursor-pointer" (click)="goToHome()" src="./assets/logos/logos updated nov 2023-62.svg"
          alt="">
      </div>

      <button class="!text-white align-middle leading-4" mat-icon-button (click)="toggleNavbar()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-sidenav-container class="!z-20" [ngClass]="{'!w-full': navbarOpened}">
  <mat-sidenav [fixedInViewport]="true" [opened]="navbarOpened" class="!w-screen" [position]="'end'">
    <div class="w-full bg-black-darkChip h-full relative">
      <div class="container pt-12 flex flex-col gap-4 text-blue-headerBg sm:w-8/12 md:w-1/2">
        <p class="p-3">CLOSED SALES</p>
        <p class="p-3">CARS2VIEW</p>
        <div class="w-full h-[1px] bg-blue-headerBg"></div>

        <div class="flex gap-3 items-center p-3" [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'cart'}"
          (click)="goToCart()">
          <div class="rounded-[100%] w-[6px] h-[6px] bg-orange"></div>
          <p>CART</p>
        </div>
        <p class="p-3" [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'shop'}" (click)="goToShop()">SHOP</p>
        <p class="p-3" [ngClass]="{'bg-black-darkChipHover text-white' : tab === 'account'}" (click)="goToAccount()">
          PROFILE
        </p>

        <app-white-btn [variant]="2" (clickEmitter)="logout()">LOGOUT</app-white-btn>

        <fa-icon class="text-white-bgGrey absolute right-8 top-7" [icon]="['far','xmark']"
          (click)="toggleNavbar()"></fa-icon>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<ng-template #placeholder>
  <app-main-content-layout>
    <div class="flex flex-col gap-4 min-w-[14rem]">
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(4)"></div>
    </div>
  </app-main-content-layout>
</ng-template>
