import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardMaintenance } from './core/guards/auth.guard';
import { AuthInterceptorService } from './core/interceptors/auth-token.interceptor';
import { AdvancedSearchModule } from './features/advanced-search/advanced-search.module';
import { AfterSearchModule } from './features/after-search/after-search.module';
import { BuyingProfileModule } from './features/buying-profile/buying-profile.module';
import { CarPageModule } from './features/car-page/car-page.module';
import { CheckoutPageModule } from './features/checkout-page/checkout-page.module';
import { MariaSelectionModule } from './features/maria-selection/maria-selection.module';
import { MarketingDataPropositionModule } from './features/marketing-data-proposition/marketing-data-proposition.module';
import { MyAccountPageModule } from './features/my-account-page/my-account-page.model';
import { MyOffersModule } from './features/my-offers/my-offers.module';
import { MyOrdersModule } from './features/my-orders/my-orders.module';
import { MyProfileFavoritesModule } from './features/my-profile-favorites/my-profile-favorites.module';
import { SavedSearchesModule } from './features/saved-searches/saved-searches.module';
import { ShoppingCartModule } from './features/shopping-cart/shopping-cart.module';
import { SharedModule } from './shared/shared.module';
import { CoreService } from './core/services/core.service';
import { LoginComponent } from './features/login/login.component';
import { RestrictedPageComponent } from './features/restricted-page/restricted-page.component';
import { MSAL_INSTANCE, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { TobComponent } from './core/tob/tob.component';
import { CookieModalComponent } from './features/cookie-modal/cookie-modal.component';

export function coreServiceFactory(coreService: CoreService): Function {
  return () => coreService.getUserInfo();
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.MSAL_CLIENT_ID,
      redirectUri: environment.MSAL_LOGIN_REDIRECT_URI,
      authority: environment.MSAL_AUTHORITY,
      postLogoutRedirectUri: environment.MSAL_LOGOUT_REDIRECT_URI,
      knownAuthorities: [environment.MSAL_KNOWN_AUTHORITIES],
    },
    cache: {
      cacheLocation: 'localStorage'
    }
  })
}

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        CookieModalComponent,
        RestrictedPageComponent,
        TobComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [BrowserModule,
        AppRoutingModule,
        SharedModule,
        MarketingDataPropositionModule,
        AdvancedSearchModule,
        CarPageModule,
        ShoppingCartModule,
        CheckoutPageModule,
        MyAccountPageModule,
        MyOrdersModule,
        MyOffersModule,
        MyProfileFavoritesModule,
        BuyingProfileModule,
        MariaSelectionModule,
        SavedSearchesModule,
        AfterSearchModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        AuthGuardMaintenance,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        MsalService,
        {
            provide: APP_INITIALIZER,
            useFactory: coreServiceFactory,
            multi: true,
            deps: [CoreService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
