<div class="flex flex-col gap-4 w-full pb-4 image-slider" *ngIf="!isMobile; else mobile">
  <div class="w-full h-96 rounded-t lg:py-4 lg:border-b border-white-200">
    <img class="h-full w-full object-contain cursor-pointer" [src]="selectedImage" alt=""
      (click)="openFullScreenPhoto()">
  </div>

  <!-- <carousel [arrows]="false" [height]="150" class="!z-0">
    <div *ngFor="let image of images" class="carousel-cell w-32 h-32">
      <img class="w-full h-full object-contain" [src]="image" alt="" (click)="selectImage(image)">
    </div>
  </carousel> -->

  <ng-image-slider [images]="imageObject" #nav [animationSpeed]="0.1" [imagePopup]="false" [manageImageRatio]="true"
    [imageSize]="{width: '200px', height: '150px', space: 4}" (imageClick)="selectImage($event)"></ng-image-slider>
</div>

<ng-template #mobile>
  <!-- <carousel [arrows]="false" [dots]="true" [cellWidth]="'100%'" [height]="300" class="!z-0 image-slider">
    <div *ngFor="let image of images" class="carousel-cell w-32 h-32">
      <img class="w-full h-full object-contain" [src]="image" alt="" (click)="selectImage(image)">
    </div>
  </carousel> -->

  <ng-image-slider [images]="imageObject" #navMobile [animationSpeed]="0.1" [imagePopup]="false" [manageImageRatio]="true"
    [imageSize]="{width: '100%', height: '200px', space: 4}"></ng-image-slider>
</ng-template>
