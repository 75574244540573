import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, debounceTime, forkJoin, of, Subject, takeUntil, tap } from 'rxjs';
import { CarDetails, CarSearchBody } from 'src/app/core/models/car.model';
import { CoreService } from 'src/app/core/services/core.service';
import { ShopService } from 'src/app/core/services/shop.service';
import { AppDropdownPanelComponent } from 'src/app/shared/app-dropdown-panel/app-dropdown-panel.component';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import bodytypes from 'src/assets/json-files/bodytypes.json';
import { AllFiltersModalComponent } from '../advanced-search/all-filters-modal/all-filters-modal.component';
import { UserService } from 'src/app/core/services/user.service';
import { SaveSearchModalComponent } from './save-search-modal/save-search-modal.component';
import { Router } from '@angular/router';
import { Offer } from 'src/app/core/models/offer.model';
import { OfferClientStatuses } from '../my-offers/my-offers.component';
import { PageEvent } from '@angular/material/paginator';

export interface Filter {
  group?: string,
  groupValue: string,
  value: string,
  isChecked?: boolean,
  noOfCars?: number,
  extraValue?: string
}

export interface FiltersList {
  fuelType: Filter[],
  gearbox: Filter[],
  carGroup: Filter[],
}

@Component({
  selector: 'app-after-search',
  templateUrl: './after-search.component.html',
  styleUrls: ['./after-search.component.scss']
})

export class AfterSearchComponent implements OnInit {
  loading = new BehaviorSubject(true);
  loadingFilters = new BehaviorSubject(true);
  loadingMoreCars = new BehaviorSubject(false);

  isClosedSales = false;
  shop = 'shop';

  public filtersOpened: boolean = true;

  private filtersStringEvent: Subject<boolean> = new Subject();
  public filtersStringEvent$ = this.filtersStringEvent.asObservable();
  public destroyed = new Subject<void>();

  filters: FiltersList = { fuelType: [], gearbox: [], carGroup: [] };
  filtersSelected: Filter[] = [];

  cars: CarDetails[] = [];
  noOfCars = 0;

  years: DropdownOption[] = Array.from({ length: (new Date().getFullYear() - 1998) }, (_, i) => i + 2000).reverse().map(y => ({ value: y.toString(), viewValue: y.toString() }));
  yearsFrom: DropdownOption[] = [];
  yearsTo: DropdownOption[] = [];

  makes: DropdownOption[] = [];
  models: DropdownOption[] = [];
  modelControl = new FormControl();
  makeControl = new FormControl();

  manufactureYears = this.fb.group({
    from: new FormControl(),
    until: new FormControl()
  })

  mileageFromControl = new FormControl();
  mileageToControl = new FormControl();

  enginePowFromControl = new FormControl();
  enginePowToControl = new FormControl();

  priceFromControl = new FormControl();
  priceToControl = new FormControl();

  co2FromControl = new FormControl();
  co2ToControl = new FormControl();

  salesFactorFromControl = new FormControl();
  salesFactorToControl = new FormControl<any>(null, Validators.max(99));

  offerCarsControl = new FormControl();
  filterOffer: boolean | undefined;

  bodytypes = bodytypes.map(b => ({ ...b, isChecked: false }));

  countries: DropdownOption[] = this.coreService.countries.map(c => ({ value: c.id, viewValue: c.name }));
  countryControl = new FormControl();

  store = this.coreService.userInfo?.buyerStores[0];

  searchBody: CarSearchBody | undefined;

  @ViewChild('allFiltersPanel') allFiltersPanelRef: AppDropdownPanelComponent | undefined;

  pageSize = 20;
  pageIndex = 0;
  maxPage = 0;

  navbarOpened = false;

  innerWidth = window.innerWidth;

  constructor(private fb: FormBuilder,
    private shopService: ShopService,
    private coreService: CoreService,
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router) { }

  async ngOnInit() {
    this.isClosedSales = this.router.url === '/closed-sales';
    this.shop = this.isClosedSales ? 'closedSales' : this.shop;

    this.makes = await this.coreService.getMakes();
    this.countries = (await this.coreService.getCountries()).map(c => ({ value: c.id, viewValue: c.name }));

    this.yearsFrom = this.years;
    this.yearsTo = this.years;

    let searchQuery = sessionStorage.getItem('searchQuery');
    let searchQueryClosedSales = sessionStorage.getItem('searchQueryClosedSales');

    if (searchQuery && !this.isClosedSales) {
      let body: CarSearchBody = JSON.parse(searchQuery);

      this.loadInfo(body);
    } else if (searchQueryClosedSales && this.isClosedSales) {
      let body: CarSearchBody = JSON.parse(searchQueryClosedSales);

      this.loadInfo(body);
    } else {
      this.loadInfo();
    }

    this.filtersStringEvent$.pipe(debounceTime(1000),
      tap(() => { }),
      takeUntil(this.destroyed)).subscribe(resetPaginator => {
        this.loading.next(true);

        if (resetPaginator) {
          this.pageIndex = 0;
        }

        let filterCall = this.isClosedSales ? this.shopService.getClosedSalesCarFilterClient(this.buildSearchBody()) : this.shopService.getCarFilterClient(this.buildSearchBody());

        filterCall.subscribe(resp => {
          this.cars = resp.cars;

          this.noOfCars = resp.nrOfCars;

          this.maxPage = Math.floor(this.noOfCars / this.pageSize);

          window.scrollTo(0, 0);

          this.loading.next(false);
        });
      });

    this.enginePowFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'enginePower', 'Engine Power', 'from', 'hp');

        this.filtersStringEvent.next(true);
      })
    });

    this.enginePowToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'enginePower', 'Engine Power', 'to', 'hp');

        this.filtersStringEvent.next(true);
      })
    });

    this.mileageFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'mileage', 'Mileage', 'from', 'km');

        this.filtersStringEvent.next(true);
      })
    });

    this.mileageToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'mileage', 'Mileage', 'to', 'km');

        this.filtersStringEvent.next(true);
      })
    });

    this.priceFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'sellingPrice', 'Price', 'from', '€');

        this.filtersStringEvent.next(true);
      })
    });

    this.priceToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'sellingPrice', 'Price', 'to', '€');

        this.filtersStringEvent.next(true);
      })
    });

    this.co2FromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'co2WLTP', 'CO2', 'from', 'g/km');

        this.filtersStringEvent.next(true);
      })
    });

    this.co2ToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      setTimeout(() => {
        this.addInputFilters(value, 'co2WLTP', 'CO2', 'to', 'g/km');

        this.filtersStringEvent.next(true);
      })
    });

    this.salesFactorFromControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      if (value > 99) this.salesFactorFromControl.setValue(99, { emitEvent: false });

      setTimeout(() => {
        this.addInputFilters(value, 'salesFactor', 'Sales factor', 'from', '');

        this.filtersStringEvent.next(true);
      })
    });

    this.salesFactorToControl.valueChanges.pipe(debounceTime(500), tap(() => { }), takeUntil(this.destroyed)).subscribe((value) => {
      if (value > 99) this.salesFactorToControl.setValue(99, { emitEvent: false });

      setTimeout(() => {
        this.addInputFilters(value, 'salesFactor', 'Sales factor', 'to', '');

        this.filtersStringEvent.next(true);
      })
    });
  }

  //saves search filters for page refresh
  @HostListener('window:beforeunload', ['$event'])
  refreshPageSaveQuery($event: any): void {
    this.saveSearchQuery();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();

    this.saveSearchQuery();

    this.shopService.shopURLCache = this.isClosedSales ? 'closed-sales' : 'search'
  }

  saveSearchQuery() {
    if (this.isClosedSales) {
      sessionStorage.setItem('searchQueryClosedSales', JSON.stringify(this.buildSearchBody()));
    } else {
      sessionStorage.setItem('searchQuery', JSON.stringify(this.buildSearchBody()));
    }
  }

  toggleFilters() {
    this.filtersOpened = !this.filtersOpened;

    this.allFiltersPanelRef!.togglePanel();
  }

  loadInfo(body?: CarSearchBody) {
    let searchBody = body ? body : this.buildSearchBody();

    forkJoin({
      cars: this.isClosedSales ? this.shopService.getClosedSalesCarFilterClient(searchBody) : this.shopService.getCarFilterClient(searchBody),
      gearboxes: this.coreService.getGearboxes(),
      fuelTypes: this.coreService.getFuelTypes(),
      favoritesCars: this.userService.getUserFavoritesCars(),
    }).subscribe({
      next: resp => {
        if (resp.cars) {
          this.cars = resp.cars.cars;

          this.noOfCars = resp.cars.nrOfCars;
        }

        if (resp.favoritesCars.id) {
          this.userService.carFavorites = resp.favoritesCars.cars.map(c => c.carMainInfoId);
        }

        this.filters.fuelType = resp.fuelTypes.map(f => ({ groupValue: 'fuelType', group: 'Fuel Type', value: f.value, noOfCars: 0, isChecked: false }));
        this.filters.gearbox = resp.gearboxes.map(f => ({ groupValue: 'gearbox', group: 'Gearbox', value: f.value, noOfCars: 0, isChecked: false }));

        if (body) this.loadValues(body);

        this.maxPage = Math.floor((resp.cars ? resp.cars.nrOfCars : this.noOfCars) / this.pageSize);

        this.loading.next(false);
        this.loadingFilters.next(false);

        if (sessionStorage.getItem('car-view-id')) {
          setTimeout(() => {
            let element = document.getElementById(sessionStorage.getItem('car-view-id')!);

            if (element) {
              sessionStorage.removeItem('car-view-id');
              element.scrollIntoView();
            }
          }, 0);
        }
      },
      error: err => {
        this.loading.next(false);
        this.loadingFilters.next(false);
      }
    });
  }

  loadValues(body: CarSearchBody) {
    this.pageIndex = body.page;
    this.pageSize = body.itemsPerPage;

    if (body.gearbox) {
      body.gearbox.forEach(v => {
        let gearbox = this.filters.gearbox.find(f => f.value === v);
        gearbox ? gearbox.isChecked = true : '';

        this.filtersSelected.push({ value: v, groupValue: 'gearbox', group: 'Gearbox' });
      })
    }

    if (body.fuelType) {
      body.fuelType.forEach(v => {
        let fuelType = this.filters.fuelType.find(f => f.value === v);
        fuelType ? fuelType.isChecked = true : '';

        this.filtersSelected.push({ value: v, groupValue: 'fuelType', group: 'FuelType' });
      })
    }

    if (body.bodyType) {
      body.bodyType.forEach(v => {
        this.bodytypes.find(f => f.value === v)!.isChecked = true;

        this.filtersSelected.push({ groupValue: 'bodyType', group: 'Bodytype', value: v })
      });
    }

    if (body.enginePower) {
      this.enginePowFromControl.setValue(body.enginePower.from, { emitEvent: false });
      this.addInputFilters(body.enginePower.from, 'enginePower', 'Engine Power', 'from', 'hp');

      if (body.enginePower.to < 999999) {
        this.enginePowToControl.setValue(body.enginePower.to, { emitEvent: false });
        this.addInputFilters(body.enginePower.to, 'enginePower', 'Engine Power', 'to', 'hp');
      }
    }

    if (body.mileage) {
      this.mileageFromControl.setValue(body.mileage.from, { emitEvent: false });
      this.addInputFilters(body.mileage.from, 'mileage', 'Mileage', 'from', 'km');

      if (body.mileage.to < 999999) {
        this.mileageToControl.setValue(body.mileage.to, { emitEvent: false });
        this.addInputFilters(body.mileage.to, 'mileage', 'Mileage', 'to', 'km');
      }
    }

    if (body.sellingPrice) {
      this.priceFromControl.setValue(body.sellingPrice.from, { emitEvent: false });
      this.addInputFilters(body.sellingPrice.from, 'sellingPrice', 'Price', 'from', '€');

      if (body.sellingPrice.to < 99999999) {
        this.priceToControl.setValue(body.sellingPrice.to, { emitEvent: false });
        this.addInputFilters(body.sellingPrice.to, 'sellingPrice', 'Price', 'to', '€');
      }
    }

    if (body.co2WLTP) {
      this.co2FromControl.setValue(body.co2WLTP.from, { emitEvent: false });
      this.addInputFilters(body.co2WLTP.from, 'co2WLTP', 'CO2', 'from', 'g/km');


      if (body.co2WLTP.to < 999999) {
        this.co2ToControl.setValue(body.co2WLTP.to, { emitEvent: false });
        this.addInputFilters(body.co2WLTP.to, 'co2WLTP', 'CO2', 'to', 'g/km');
      }
    }

    if (body.salesFactor) {
      this.salesFactorFromControl.setValue(body.salesFactor.from, { emitEvent: false });
      this.salesFactorToControl.setValue(body.salesFactor.to, { emitEvent: false });

      this.addInputFilters(body.salesFactor.from, 'salesFactor', 'Sales Factor', 'from', '');
      this.addInputFilters(body.salesFactor.to, 'salesFactor', 'Sales Factor', 'to', '');
    }

    if (body.manufactureYear) {
      this.manufactureYears.controls.from.setValue(body.manufactureYear.from.toString(), { emitEvent: false });
      this.manufactureYears.controls.until.setValue(body.manufactureYear.to.toString(), { emitEvent: false });

      this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Manufacture Year from`, value: `From ${body.manufactureYear.from}`, isChecked: false });
      this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Manufacture Year to`, value: `To ${body.manufactureYear.to}`, isChecked: false });
    }

    if (body.country) {
      this.countryControl.setValue(body.country[0], { emitEvent: false });

      this.filtersSelected.push({ value: body.country[0], groupValue: 'country', group: 'Country', extraValue: this.countries.find(c => c.value === body.country![0])?.viewValue });
    }

    if (body.offers) {
      this.filtersSelected.push({
        groupValue: 'offer',
        value: 'Show cars with offers',
      });

      this.filterOffer = true;

      this.offerCarsControl.setValue(true);
    }

    if (body.makeModel) {
      this.makeControl.setValue(body.makeModel[0].make, { emitEvent: false });
      this.modelControl.setValue(body.makeModel[0].model, { emitEvent: false });

      this.loadModels(body.makeModel[0].make);

      this.filtersSelected.push({ groupValue: 'make', value: body.makeModel[0].make, group: 'Make', isChecked: true });
      if (body.makeModel[0].model.length > 0) this.filtersSelected.push({ groupValue: 'model', value: body.makeModel[0].model, group: 'Model', isChecked: true });
    }
  }

  changeFilter(filter: Filter) {
    this.filtersSelected.includes(filter) ? this.removeFilter(filter) : this.addFilter(filter);
  }

  addFilter(filter: Filter) {
    this.filtersSelected.push(filter);

    this.filtersStringEvent.next(true);
  }

  removeFilter(filter: Filter) {
    this.filtersSelected.splice(this.filtersSelected.indexOf(filter), 1);

    if (this.filters[filter.groupValue as keyof typeof this.filters]) {
      this.filters[filter.groupValue as keyof typeof this.filters]!.find(f => f.value === filter.value)!.isChecked = false;
    }

    if (filter.groupValue === 'country') {
      this.countryControl.reset(null, { emitEvent: false });
    }

    if (filter.groupValue === 'bodyType') {
      this.bodytypes.find(b => b.value === filter.value)!.isChecked = false;
    }

    if (filter.groupValue === 'mileage' && filter.value === 'from') {
      this.mileageFromControl.reset(null, { emitEvent: false });
    } else if (filter.groupValue === 'mileage' && filter.value === 'to') {
      this.mileageToControl.reset(null, { emitEvent: false });
    }

    if (filter.groupValue === 'enginePower' && filter.value === 'from') {
      this.enginePowFromControl.reset(null, { emitEvent: false });
    } else if (filter.groupValue === 'enginePower' && filter.value === 'to') {
      this.enginePowToControl.reset(null, { emitEvent: false });
    }

    if (filter.groupValue === 'sellingPrice' && filter.value === 'from') {
      this.priceFromControl.reset(null, { emitEvent: false });
    } else if (filter.groupValue === 'sellingPrice' && filter.value === 'to') {
      this.priceToControl.reset(null, { emitEvent: false });
    }

    if (filter.groupValue === 'co2WLTP' && filter.value === 'from') {
      this.co2FromControl.reset(null, { emitEvent: false });
    } else if (filter.groupValue === 'co2WLTP' && filter.value === 'to') {
      this.co2ToControl.reset(null, { emitEvent: false });
    }

    if (filter.group === 'Manufacture Year from') {
      this.manufactureYears.controls.from.reset(null, { emitEvent: false });
      this.yearsTo = this.years;
    } else if (filter.group === 'Manufacture Year to') {
      this.manufactureYears.controls.until.reset(null, { emitEvent: false });
      this.yearsFrom = this.years;
    }

    if (filter.groupValue === 'make') {
      this.modelControl.reset(null, { emitEvent: false });
      this.models = [];
      this.makeControl.reset(null, { emitEvent: false });
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === 'model'), 1);
    }

    if (filter.groupValue === 'model') {
      this.modelControl.reset(null, { emitEvent: false });
    }

    if (filter.groupValue === 'offers') {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === 'offers'), 1);
    }

    this.filtersStringEvent.next(true);
  }

  selectMake(make: string) {
    let index = this.filtersSelected.findIndex(f => f.groupValue === 'make');

    if (make && index >= 0) {
      this.filtersSelected[index].value = make;
    } else if (make && index < 0) {
      this.filtersSelected.push({
        groupValue: 'make',
        value: make,
        group: 'Make',
        isChecked: true
      });
    } else if (!make && index >= 0) {
      this.filtersSelected.splice(index);
    }

    this.modelControl.reset();
    this.models = [];

    if (make) {
      this.loadModels(make);
    }

    this.filtersStringEvent.next(true);
  }

  selectModel(model: string) {
    let index = this.filtersSelected.findIndex(f => f.groupValue === 'model');

    if (model && index >= 0) {
      this.filtersSelected[index].value = model;
    } else if (model && index < 0) {
      this.filtersSelected.push({
        groupValue: 'model',
        group: 'Model',
        value: model,
        isChecked: true
      })
    } else if (!model && index >= 0) {
      this.filtersSelected.splice(index);
    }

    this.filtersStringEvent.next(true);
  }

  selectCountry(countryId: string) {
    let filter = this.filtersSelected.find(f => f.groupValue === 'country');

    if (!countryId && filter) {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === filter?.groupValue && f.value === filter.value));
    } else if (filter) {
      filter.extraValue = this.countries.find(c => c.value === countryId)?.viewValue
      filter.value = countryId;
    } else {
      this.filtersSelected.push({
        value: countryId,
        groupValue: 'country',
        group: 'Country',
        extraValue: this.countries.find(c => c.value === countryId)?.viewValue
      })
    }

    this.filtersStringEvent.next(true);
  }

  selectManYear(year: string, yearType: string, noEmit: boolean = false) {
    let yearFrom = this.manufactureYears.controls.from.value ? this.manufactureYears.controls.from.value : undefined;
    let yearTo = this.manufactureYears.controls.until.value ? this.manufactureYears.controls.until.value : undefined;

    if (!year) {
      let index = this.filtersSelected.findIndex(f => f.group === `Manufacture Year ${yearType}`);

      index >= 0 ? this.filtersSelected.splice(index, 1) : 0;

      if (yearType === 'from' && yearTo) {
        this.yearsTo = this.years;

        this.filterYearArray('from', yearTo);
      } else if (yearType === 'to' && yearFrom) {
        this.yearsFrom = this.years;

        this.filterYearArray('to', yearFrom);
      } else {
        this.yearsTo = this.years;
        this.yearsFrom = this.years;
      }

      this.manufactureYears.controls[yearType as keyof typeof this.manufactureYears.controls].reset(null, { emitEvent: false });
    } else {
      let filter = this.filtersSelected.find(f => f.group === `Manufacture Year ${yearType}`);

      if (filter) {
        filter.value = `${yearType.charAt(0).toUpperCase() + yearType.slice(1)} ${year}`;
      } else {
        this.filtersSelected.push({ groupValue: 'manufactureYear', group: `Manufacture Year ${yearType}`, value: `${yearType.charAt(0).toUpperCase() + yearType.slice(1)} ${year}`, isChecked: false });
      }

      this.filterYearArray(yearType, year);
    }

    if (!noEmit) this.filtersStringEvent.next(true);
  }

  filterYearArray(yearType: string, complementYear: string) {
    if (yearType === 'from') {
      this.yearsTo = this.years.slice(0, this.years.findIndex(y => y.value === complementYear) + 1);
    } else {
      this.yearsFrom = this.years.slice(this.years.findIndex(y => y.value === complementYear));
    }
  }

  filterOfferEmit(offer: boolean) {
    if (offer) {
      this.filtersSelected.push({
        groupValue: 'offer',
        value: 'Show cars with offers',
      });
    } else if (!offer && this.filtersSelected.find(f => f.groupValue === 'offer')) {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.groupValue === 'offer'), 1);
    }

    this.filterOffer = offer ? true : undefined;

    this.filtersStringEvent.next(true);
  }

  loadModels(make: string) {
    this.shopService.getCarNomenclatorData(`models?make=${make}`).subscribe(resp => {
      this.models = resp.map(r => ({ value: r, viewValue: r }));
    });
  }

  checkBodytype(event: boolean, body: string) {
    if (event) {
      this.filtersSelected.push({
        groupValue: 'bodyType',
        group: 'Bodytype',
        value: body,
      })
    } else {
      this.filtersSelected.splice(this.filtersSelected.findIndex(f => f.value === body), 1);
    }

    this.filtersStringEvent.next(true);
  }

  openAllFiltersModal() {
    const dialogRef = this.dialog.open(
      AllFiltersModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        filters: this.filtersSelected
      },
      panelClass: 'modal-bg-black',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && resp.filters && resp.filters.length === 0) {
        this.clearFilters();
      } else if (resp) {
        this.filtersSelected = resp.filters;
        this.updateFilters(resp.filtersToDelete);
      }
    });
  }

  updateFilters(filtersToDelete: Filter[]) {
    filtersToDelete.forEach(f => {
      switch (f.groupValue) {
        case 'bodyType':
          this.bodytypes.find(b => b.value === f.value)!.isChecked = false;
          break;
        case 'fuelType':
          this.filters.fuelType.find(fl => fl.value === f.value)!.isChecked = false;
          break;
        case 'gearbox':
          this.filters.gearbox.find(g => g.value === f.value)!.isChecked = false;
          break;
        case 'mileage':
          if (f.value === 'from') {
            this.mileageFromControl.reset(null, { emitEvent: false });
          } else {
            this.mileageToControl.reset(null, { emitEvent: false });
          }

          break;
        case 'enginePower':
          if (f.value === 'from') {
            this.enginePowFromControl.reset(null, { emitEvent: false });
          } else {
            this.enginePowToControl.reset(null, { emitEvent: false });
          }

          break;
        case 'sellingPrice':
          if (f.value === 'from') {
            this.priceFromControl.reset(null, { emitEvent: false });
          } else {
            this.priceToControl.reset(null, { emitEvent: false });
          }

          break;
        case 'co2WLTP':
          if (f.value === 'from') {
            this.co2FromControl.reset(null, { emitEvent: false });
          } else {
            this.co2ToControl.reset(null, { emitEvent: false });
          }

          break;
        case 'salesFactor':
          if (f.value === 'from') {
            this.salesFactorFromControl.reset(null, { emitEvent: false });
          } else {
            this.salesFactorToControl.reset(null, { emitEvent: false });
          }

          break;
        case 'model':
          this.modelControl.reset(null, { emitEvent: false });
          break;
        case 'make':
          this.modelControl.reset(null, { emitEvent: false });
          this.models = [];
          this.makeControl.reset(null, { emitEvent: false });

          break;
        case 'manufactureYear':
          if (f.group!.match('from')) {
            this.manufactureYears.controls.from.reset(null, { emitEvent: false });
            this.yearsFrom = this.years;
            this.yearsTo = this.years;
          } else {
            this.manufactureYears.controls.until.reset(null, { emitEvent: false });
            this.yearsFrom = this.years;
            this.yearsTo = this.years;
          }
          break;
        case 'country':
          this.countryControl.reset(null, { emitEvent: false });
          break;
        default:
          break;
      }
    });

    this.filtersStringEvent.next(true);
  }

  clearFilters() {
    this.filtersSelected = [];

    this.filters.fuelType = this.filters.fuelType.map(f => ({ ...f, isChecked: false }));
    this.filters.gearbox = this.filters.gearbox.map(f => ({ ...f, isChecked: false }));
    this.bodytypes = this.bodytypes.map(f => ({ ...f, isChecked: false }));

    this.yearsFrom = this.years;
    this.yearsTo = this.years;

    this.countryControl.reset(null, { emitEvent: false });

    this.makeControl.reset(null, { emitEvent: false })
    this.modelControl.reset(null, { emitEvent: false });
    this.models = [];

    this.manufactureYears.reset(undefined, { emitEvent: false });

    this.enginePowFromControl.reset(null, { emitEvent: false });
    this.enginePowToControl.reset(null, { emitEvent: false });

    this.mileageFromControl.reset(null, { emitEvent: false });
    this.mileageToControl.reset(null, { emitEvent: false });

    this.priceFromControl.reset(null, { emitEvent: false });
    this.priceToControl.reset(null, { emitEvent: false });

    this.co2FromControl.reset(null, { emitEvent: false });
    this.co2ToControl.reset(null, { emitEvent: false });

    this.salesFactorFromControl.reset(null, { emitEvent: false });
    this.salesFactorToControl.reset(null, { emitEvent: false });

    this.offerCarsControl.reset();
    this.filterOffer = undefined;

    this.filtersStringEvent.next(true);
  }

  addInputFilters(value: number, groupValue: string, group: string, type: string, unit: string) {
    let index = this.filtersSelected.findIndex(f => f.groupValue === groupValue && f.value === type);

    if (index < 0 && value && value > 0) {
      this.filtersSelected.push({ groupValue: groupValue, group: group, value: type, extraValue: `${type.charAt(0).toUpperCase() + type.slice(1)} ${value.toLocaleString()} ${unit}` })
    } else if (index >= 0 && value && value > 0) {
      this.filtersSelected[index].extraValue = `${type.charAt(0).toUpperCase() + type.slice(1)} ${value.toLocaleString()} ${unit}`;
    } else if (index >= 0 && (!value || value === 0)) {
      this.filtersSelected.splice(index, 1);
    }
  }

  openSaveSearchModal() {
    this.dialog.open(
      SaveSearchModalComponent, {
      width: '650px',
      autoFocus: false,
      data: {
        filters: this.filtersSelected,
        search: this.buildSearchBody()
      }
    });
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.filtersStringEvent.next(false);
  }

  buildSearchBody(): CarSearchBody {
    let body: CarSearchBody = {
      page: this.pageIndex,
      itemsPerPage: this.pageSize,
    };

    if (this.filtersSelected.filter(f => f.groupValue === "gearbox").length > 0) {
      body.gearbox = this.filtersSelected.filter(f => f.groupValue === "gearbox").map(f => f.value);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "fuelType").length > 0) {
      body.fuelType = this.filtersSelected.filter(f => f.groupValue === "fuelType").map(f => f.value);
    }

    if (this.filtersSelected.filter(f => f.groupValue === "bodyType").length > 0) {
      body.bodyType = this.filtersSelected.filter(f => f.groupValue === "bodyType").map(f => f.value);
    }

    if (this.filtersSelected.find(f => f.groupValue === "country")) {
      body.country = [this.filtersSelected.find(f => f.groupValue === "country")!.value];
    }

    if (this.manufactureYears.controls.from.value || this.manufactureYears.controls.until.value) {
      body.manufactureYear = {
        from: parseInt(this.manufactureYears.controls.from.value) ? parseInt(this.manufactureYears.controls.from.value) : 2000,
        to: parseInt(this.manufactureYears.controls.until.value) ? parseInt(this.manufactureYears.controls.until.value) : parseInt(this.years[0].value)
      }
    }

    if (this.mileageFromControl.value || this.mileageToControl.value) {
      body.mileage = {
        from: this.mileageFromControl.value ? this.mileageFromControl.value : 0,
        to: this.mileageToControl.value ? this.mileageToControl.value : 999999
      }
    }

    if (this.enginePowFromControl.value || this.enginePowToControl.value) {
      body.enginePower = {
        from: this.enginePowFromControl.value ? this.enginePowFromControl.value : 0,
        to: this.enginePowToControl.value ? this.enginePowToControl.value : 999999
      }
    }

    if (this.priceFromControl.value || this.priceToControl.value) {
      body.sellingPrice = {
        from: this.priceFromControl.value ? this.priceFromControl.value : 0,
        to: this.priceToControl.value ? this.priceToControl.value : 99999999
      }
    }

    if (this.co2FromControl.value || this.co2ToControl.value) {
      body.co2WLTP = {
        from: this.co2FromControl.value ? this.co2FromControl.value : 0,
        to: this.co2ToControl.value ? this.co2ToControl.value : 999999
      }
    }

    if (this.salesFactorFromControl.value || this.salesFactorToControl.value) {
      body.salesFactor = {
        from: this.salesFactorFromControl.value ? this.salesFactorFromControl.value : 0,
        to: this.salesFactorToControl.value ? this.salesFactorToControl.value : 99
      }
    }

    if (this.makeControl.value) {
      body.makeModel = [
        {
          make: this.makeControl.value,
          model: this.modelControl.value ? this.modelControl.value : ''
        }
      ]
    }

    if (this.filterOffer) {
      body.offers = true;
    }

    return body;
  }

  @HostListener('window:resize', ['$event'])
  resizeChart() {
    this.innerWidth = window.innerWidth;
  }
}
