import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CookieModalComponent>) { }

  ngOnInit() {
  }

  closeModal(event: boolean) {
    this.dialogRef.close(event);
  }
}
