<app-my-profile-page-layout [profilePage]="'orders'">
  <div class="flex flex-col gap-6 " *ngIf="!(loading | async);else placeholder">
    <app-tabs [tabs]="tabs" [tabSelected]="tabSelected" (clickEmitter)="changeTab($event)"></app-tabs>

    <div [ngSwitch]="tabSelected">
      <div *ngSwitchCase="'pending'">
        <p>These orders are in process waiting for final approval by Cars2click</p>
      </div>

      <div *ngSwitchCase="'approved'">
        <p>Orders with cars confirmed / approved</p>
      </div>

      <div *ngSwitchCase="'canceled'">
        <p>Orders with all cars cancelled by admin</p>
      </div>

      <div *ngSwitchCase="'finished'">
        <p>These orders are finalized</p>
      </div>
    </div>

    <mat-button-toggle-group [(ngModel)]="orderView" name="orderView" (valueChange)="changeView($event)"
      class="!rounded-full w-fit text-sm !border-none" id="order-view" disableRipple>
      <mat-button-toggle class="rounded-tl-full rounded-bl-full px-4 py-1" value="orders">Order view</mat-button-toggle>
      <mat-button-toggle class="rounded-tr-full rounded-br-full px-4 py-1" value="flatview">Cars
        view</mat-button-toggle>
    </mat-button-toggle-group>

    <!-- <div class="flex flex-col gap-9 md:flex-row lg:w-fit xs:bg-white xs:px-6 xs:py-9 items-center">
      <div class="flex flex-col sm:flex-row gap-9 sm:gap-6 w-full">
        <app-datepicker class=" border border-white-lines xs:border-none w-full" id="from-filter" label="From"
          (dateChangeEvent)="filter($event,'from')" [control]="fromControl"></app-datepicker>

        <app-datepicker class="border border-white-lines xs:border-none w-full" id="to-filter" label="To"
          (dateChangeEvent)="filter($event,'to')" [control]="toControl"></app-datepicker>
      </div>

      <div class="flex justify-center sm:justify-end md:justify-start w-fit">
        <app-white-btn class="justify-self-center sm:justify-self-end w-fit" id="reset-filters"
          (clickEmitter)="resetFilters()">Reset</app-white-btn>
      </div>
    </div> -->

    <div [ngSwitch]="orderView">
      <div *ngSwitchCase="'orders'">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
          *ngIf="filteredOrders.length > 0; else noOrders">

          <div class="bg-white rounded px-6 py-9" *ngFor="let order of filteredOrders">
            <div class="flex justify-between gap-4 pb-6 border-b border-white-lines">
              <div>
                <p class="font-bold mb-3 order-number break-all">Order number {{order.orderId}}</p>
                <p class="text-sm text-white-greyText">Placed on {{order.orderDate}}</p>
              </div>

              <div class="p-2 bg-white-bgGrey w-fit relative rounded h-min">
                <div class="bg-orange w-4 h-4 rounded-[50%] opacity-20"></div>
                <div class="bg-orange w-2 h-2 absolute right-2 top-4 rounded-br-[100%]"></div>
              </div>

              <!-- <div *ngSwitchCase="'await'">
              <fa-icon class="text-orange fa-icon-div" [icon]="['fas','circle-exclamation']"
                matTooltip="Required validation" matTooltipPosition="above"></fa-icon>
            </div>

            <div *ngSwitchCase="'past'">
              <fa-icon class="text-green fa-icon-div" [icon]="['fas','circle-check']"></fa-icon>
            </div> -->
            </div>

            <div class="flex justify-between py-6">
              <div class="">
                <p class="font-bold mb-3 text-blue-ctaBlue">€{{order!.totalInvoiced | number}}</p>
                <p class="text-sm text-white-greyText">Order amount</p>
              </div>

              <div class="flex flex-col gap-1">
                <p class="text-sm text-white-greyText">Cars ordered: {{order!.carsInOrder}}</p>
                <p class="text-sm text-white-greyText">Cars confirmed: {{order!.confirmedCars}}</p>
                <p class="text-sm text-white-greyText">Cars cancelled: {{order!.canceledCars}}</p>
              </div>
            </div>

            <app-white-btn [variant]="4" (clickEmitter)="goToOrder(order)">View order</app-white-btn>
          </div>
        </div>
      </div>

      <!-- flat view cars -->
      <div *ngSwitchCase="'flatview'">
        <div class="flex gap-6 items-center mb-10 relative">
          <app-input label="VIN" placeholder="Insert VIN" [control]="vinControl"></app-input>

          <p *ngIf="vinControl.hasError('minlength') && vinControl.touched"
            class="absolute -bottom-6 left-1 text-xs text-red-100">Min 3 characters required</p>

          <app-blue-btn (clickEmitter)="loadTable()">Search</app-blue-btn>
        </div>

        <div class="overflow-x-auto" *ngIf="!(loadingTable | async);else placeholderTable">
          <table mat-table matSort [dataSource]="dataSource"
            class="mat-elevation-z8 w-full shadow-none whitespace-nowrap">

            <ng-container [matColumnDef]="col.value" *ngFor="let col of headersMap">
              <th mat-header-cell *matHeaderCellDef class="uppercase !min-w-[10rem] !px-3 !py-1">
                {{col.viewValue}}
              </th>

              <td mat-cell *matCellDef="let element" class="whitespace-normal !min-w-[10rem] !px-3 !py-1">
                {{getValue(element, col)}}
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>
                CAR DETAILS
              </th>

              <td class="!py-2" mat-cell *matCellDef="let element">
                <div class="flex flex-wrap gap-3 w-max">
                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.make}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.model}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.variant}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3" *ngIf="element.trim">
                    {{element.trim}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.fuelType}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.gearbox}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.mileage}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.enginePower}}
                  </p>

                  <p class="!border-r !border-white-lines !pr-3">
                    {{element.chassis}}
                  </p>

                  <p>
                    {{element.firstReg}}
                  </p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="details">
              <th mat-header-cell *matHeaderCellDef>
              </th>

              <td class="!py-2" mat-cell *matCellDef="let element">
                <app-white-btn *ngIf="element.carStatus != 'Canceled'" [variant]="4"
                  (clickEmitter)="openViewCarModal(element.carMainInfoId)">View car</app-white-btn>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <tr [ngClass]="{'isSelected' : row.isSelected}" class="hover:bg-white-100" mat-row
              *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <!-- <mat-paginator class="bg-white-400" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
          </mat-paginator> -->
        </div>
      </div>
    </div>
  </div>
</app-my-profile-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
    <app-car-card-placeholder *ngFor="let i of [].constructor(10)"></app-car-card-placeholder>
  </div>
</ng-template>

<ng-template #placeholderTable>
  <div class="flex flex-col gap-2 col-span-2">
    <div class="bg-white-100 h-14"></div>
    <div class="placeholder-animation h-14" *ngFor="let i of [].constructor(5)"></div>
  </div>
</ng-template>

<ng-template #noOrders>
  <div class="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
    <div class="sm:col-span-2 lg:col-span-3 xl:col-span-4 flex justify-center p-6 h-full flex-col">
      <app-no-results-placeholder [iconClass]="'far'" [icon]="'file-lines'">
      </app-no-results-placeholder>

      <div class="self-center mt-6">
        <p class="text-2xl">No orders!</p>
      </div>
    </div>
  </div>
</ng-template>
