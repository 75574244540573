<app-nav-bar-after-login [tab]="shop"></app-nav-bar-after-login>

<div class="hidden lg:block bg-cover pt-16" [ngClass]="isClosedSales ? 'bg-closed-sales-banner' : 'bg-banner'">
  <div class="container flex justify-between">
    <div class="flex flex-col gap-6 justify-center w-1/3 text-white">
      <div>
        <img src="./assets/logos/c2c-short-white-logo.svg" alt="">
      </div>
      <p class="text-2xl font-bold">
        {{isClosedSales ? 'Welcome to our closed sales shop' : 'Welcome to our online cross-border B2B shop'}}
      </p>
      <p>Enter our shop and discover amazing offers and great cars at the best prices.</p>
    </div>

    <div>
      <img [src]="isClosedSales ? './assets/photos/car-red.png' : './assets/photos/car-blue.png'" alt="">
    </div>
  </div>
</div>

<div class="bg-white-bgGrey min-h-[80vh] pt-16">
  <div class="container grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 py-5">
    <ng-container *ngIf="innerWidth >= 768" [ngTemplateOutlet]="filtersPanel"></ng-container>

    <div class="flex justify-between items-center px-6 py-3 rounded bg-white" *ngIf="innerWidth < 768"
      (click)="navbarOpened = true">
      <p>Filters</p>

      <fa-icon [icon]="['far','bars-filter']"></fa-icon>
    </div>

    <div *ngIf="!(loading | async);else placeholderCars" class="col-span-1 md:col-span-2 xl:col-span-3 h-auto">
      <div class="h-full flex flex-col gap-6" *ngIf="cars.length>0;else noResults">

        <app-car-shop-card *ngFor="let car of cars" [car]="car" [isClosedSales]="isClosedSales"
          [id]="car.carMainInfoId"></app-car-shop-card>

        <div *ngIf="loadingMoreCars | async" class="col-span-1 md:col-span-2 xl:col-span-3 flex flex-col gap-6">
          <app-car-shop-card-placeholder *ngFor="let i of [].constructor(5)"></app-car-shop-card-placeholder>
        </div>

        <mat-paginator #carPaginator [pageIndex]="pageIndex" [pageSizeOptions]="[20,50,100]" [length]="noOfCars"
          [pageSize]="pageSize" (page)="changePage($event)" aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>


<div class="hidden md:block bottom-0 right-0 fixed w-fit" [ngClass]="{'!hidden' : !filtersOpened}">
  <div class="px-3 py-2 rounded-t mr-4 text-white bg-black w-min self-end cursor-pointer" (click)="toggleFilters()">
    <fa-icon [icon]="['far','chevron-up']"></fa-icon>
  </div>
</div>

<!-- filters bottom drawer -->
<div class="hidden fixed bottom-0 w-full flex-col z-50" [ngClass]="{'!flex' : allFiltersPanel.expandedPanel}">
  <div class="px-3 py-2 rounded-t mr-4 text-white bg-black w-min self-end cursor-pointer" (click)="toggleFilters()">
    <fa-icon [icon]="['far','chevron-down']"></fa-icon>
  </div>

  <app-dropdown-panel class="bg-black" #allFiltersPanel panelId="allFiltersPanel" [checkResize]="true">
    <div class="container py-8 flex flex-col lg:flex-row justify-between gap-6 bg-black">
      <div class="flex flex-wrap gap-3 text-sm">
        <div class="px-4 py-3 rounded bg-black-darkChip text-blue-ctaBlue" [ngClass]="{'!text-orange' : isClosedSales}">
          <p>{{noOfCars | number}} results</p>
        </div>

        <div *ngFor="let filter of filtersSelected | slice:0:8;"
          class="px-4 py-3 rounded bg-black-darkChip text-white-bgGrey gap-4 hidden md:flex hover:bg-black-darkChipHover">
          <p>{{filter.extraValue ? filter.extraValue : filter.value}}</p>

          <fa-icon [id]="'remove'+ filter.value +'-btn'" [attr.data-cy]="'remove'+ filter.value +'-btn'"
            [icon]="['far','xmark']" class="cursor-pointer" (click)="removeFilter(filter)"></fa-icon>
        </div>

        <div class="flex items-center">
          <p id="sell-all-filters-btn" data-cy="sell-all-filters-btn"
            class="text-white-greyText cursor-pointer hover:text-white-hoverGrey hover:underline md:hidden"
            (click)="openAllFiltersModal()">see all</p>
        </div>

        <div class="flex items-center" *ngIf="filtersSelected.length > 8">
          <p id="sell-all-filters-btn" data-cy="sell-all-filters-btn"
            class="text-white-greyText cursor-pointer hover:text-white-hoverGrey hover:underline hidden md:block"
            (click)="openAllFiltersModal()">see all</p>
        </div>
      </div>

      <div class="flex gap-1 lg:gap-4">
        <app-black-btn id="clear-filters" (clickEmitter)="clearFilters()">Clear filters</app-black-btn>
        <app-blue-btn *ngIf="!isClosedSales" id="open-save-search" (clickEmitter)="openSaveSearchModal()">Save
          search</app-blue-btn>
      </div>
    </div>
  </app-dropdown-panel>
</div>

<app-footer-after-login></app-footer-after-login>

<mat-sidenav-container class="!z-20" [ngClass]="{'w-full': navbarOpened}">
  <mat-sidenav [fixedInViewport]="true" [opened]="navbarOpened" class="w-screen" [position]="'start'">
    <ng-container *ngIf="innerWidth < 768" [ngTemplateOutlet]="filtersPanel"></ng-container>
  </mat-sidenav>
</mat-sidenav-container>


<!-- side-filters panel -->
<ng-template #filtersPanel>
  <div class="col-span-1 w-full flex flex-col gap-4 bg-white rounded p-9 md:sticky top-0 overflow-y-scroll h-screen"
    [ngClass]="{'closedSales' : isClosedSales}" *ngIf="!(loadingFilters | async);else filtersPlaceholder">

    <div class="flex gap-6 items-center">
      <fa-icon class="md:hidden" (click)="navbarOpened = false" [icon]="['far','chevron-left']"></fa-icon>
      <p class="text-2xl hidden md:inline">Search cars</p>
      <p class="text-2xl  md:hidden">Filters</p>
    </div>

    <div class="flex justify-between items-center text-sm my-3">
      <p class="text-white-greyText">{{noOfCars | number}} results</p>

      <p class="cursor-pointer active:scale-95" (click)="clearFilters()">Reset filters</p>
    </div>

    <div class="flex flex-col gap-9 mt-6">
      <!-- offer -->
      <div class="flex gap-3 items-center">
        <app-toggle [control]="offerCarsControl" label="Show cars with offers only"
          (toggleEmitter)="filterOfferEmit($event)"></app-toggle>

        <fa-icon [icon]="['fas','circle-info']"
          class="w-8 h-8 flex items-center justify-center bg-white-bgGrey rounded text-blue-ctaBlue"
          matTooltip="These are cars already included in all shop offers, from all users"
          matTooltipPosition="above"></fa-icon>
      </div>

      <!-- make model -->
      <app-dropdown id="make" [options]="makes" [control]="makeControl" label="Make" placeholder="Select make"
        label="Make" (emitOnChangeSelect)="selectMake($event)" [resetSelection]="true"></app-dropdown>

      <app-dropdown class="pb-4 border-b border-white-lines" id="model" [options]="models" [control]="modelControl"
        label="Model" placeholder="Select model" label="Model" (emitOnChangeSelect)="selectModel($event)"
        [resetSelection]="true"></app-dropdown>

      <!-- price -->
      <app-input-number id="price-from" [control]="priceFromControl" placeholder="Insert price from €"
        label="Price from €" [min]="0"></app-input-number>

      <app-input-number [min]="0" class="pb-4 border-b border-white-lines" id="price-to" [control]="priceToControl"
        placeholder="Insert price to €" label="Price to €"></app-input-number>

      <!-- mileage -->
      <app-input-number [min]="0" id="mileage-from" placeholder="Insert mileage from KM" label="Mileage from KM"
        [control]="mileageFromControl"></app-input-number>

      <app-input-number [min]="0" class="pb-4 border-b border-white-lines" id="mileage-to"
        placeholder="Insert mileage to KM" label="Mileage to KM" [control]="mileageToControl"></app-input-number>

      <!-- manufacture year -->
      <app-dropdown id="year-from" [options]="yearsFrom" (emitOnChangeSelect)="selectManYear($event,'from')"
        placeholder="Select year from" label="Manufacture year from" [control]="manufactureYears.controls.from"
        [resetSelection]="true"></app-dropdown>

      <app-dropdown class="pb-4 border-b border-white-lines" id="year-to" [options]="yearsTo"
        (emitOnChangeSelect)="selectManYear($event,'to')" placeholder="Select year to" label="Manufacture year to"
        [control]="manufactureYears.controls.until" [resetSelection]="true"></app-dropdown>
    </div>

    <!-- fueltype -->
    <div class="flex justify-between text-xs text-white-greyText pt-2 cursor-pointer"
      (click)="fueltypePanel.togglePanel()">
      <p>Fuel type</p>

      <fa-icon [icon]="['far',fueltypePanel.expandedPanel ? 'chevron-up' : 'chevron-down']"></fa-icon>
    </div>

    <app-dropdown-panel #fueltypePanel panelId="fueltypePanel">
      <div class="flex flex-col gap-6">
        <app-checkbox [orangeColor]="isClosedSales" [label]="fueltype.value" *ngFor="let fueltype of filters.fuelType"
          (checkboxValueChange)="changeFilter(fueltype)" [(checkboxValue)]="fueltype.isChecked!"
          [componentId]="fueltype.value"></app-checkbox>
      </div>
    </app-dropdown-panel>

    <!-- sales factor -->
    <div class="relative border-t border-white-lines pt-9">
      <app-input-number [min]="0" [max]="99" id="sales-factor-from" placeholder="Insert sales factor from"
        label="Sales factor from" [control]="salesFactorFromControl"></app-input-number>

      <fa-icon class="absolute top-3 right-1 text-white-disabled text-sm" [icon]="['fas','circle-info']"
        matTooltip="Sales factor represents the balance in a market for 30 days of supply. The lower the value, the higher the demand. A sales factor of 30 is a perfectly balance market for that product"></fa-icon>
    </div>

    <app-input-number [min]="0" [max]="99" class="mt-5 pb-4 border-b border-white-lines" id="sales-factor-to"
      placeholder="Insert sales factor to" label="Sales factor to (Max value 99)"
      [control]="salesFactorToControl"></app-input-number>

    <app-dropdown-panel #moreFiltersPanel panelId="moreFiltersPanel" [checkResize]="true">
      <div class="flex flex-col gap-4">
        <!-- gearbox -->
        <div class="flex justify-between text-xs text-white-greyText pt-2 cursor-pointer"
          (click)="gearboxPanel.togglePanel()">
          <p>Gearbox</p>

          <fa-icon [icon]="['far',gearboxPanel.expandedPanel ? 'chevron-up' : 'chevron-down']"></fa-icon>
        </div>

        <app-dropdown-panel #gearboxPanel panelId="gearboxPanel" class="border-b border-white-lines">
          <div class="flex flex-col gap-6 pb-6">
            <app-checkbox [orangeColor]="isClosedSales" [label]="gearbox.value" *ngFor="let gearbox of filters.gearbox"
              (checkboxValueChange)="changeFilter(gearbox)" [(checkboxValue)]="gearbox.isChecked!"
              [componentId]="gearbox.value"></app-checkbox>
          </div>
        </app-dropdown-panel>

        <!-- bodytypes -->
        <div class="flex justify-between text-xs text-white-greyText pt-2 cursor-pointer"
          (click)="bodytypePanel.togglePanel()">
          <p>Bodytype</p>

          <fa-icon [icon]="['far',bodytypePanel.expandedPanel ? 'chevron-up' : 'chevron-down']"></fa-icon>
        </div>

        <app-dropdown-panel #bodytypePanel panelId="bodytypePanel" class="border-b border-white-lines">
          <div class="flex flex-col gap-6 pb-6">
            <app-checkbox [orangeColor]="isClosedSales" *ngFor="let body of bodytypes" [label]="body.displayName"
              (checkboxValueChange)="checkBodytype($event, body.value)" [(checkboxValue)]="body.isChecked!"
              [componentId]="body.displayName"></app-checkbox>
          </div>
        </app-dropdown-panel>

        <div class="flex flex-col gap-9 mt-6">
          <!-- engine power -->
          <app-input-number [min]="0" id="power-from" [control]="enginePowFromControl"
            placeholder="Insert engine power from" label="Engine power (HP) from"></app-input-number>

          <app-input-number [min]="0" class="pb-4 border-b border-white-lines" id="power-to"
            [control]="enginePowToControl" placeholder="Insert engine power to"
            label="Engine power (HP) to"></app-input-number>

          <!-- co2 -->
          <app-input-number [min]="0" id="co2-from" [control]="co2FromControl" placeholder="Select CO2 WLTP from"
            label="CO2 WLTP from"></app-input-number>

          <app-input-number [min]="0" class="pb-4 border-b border-white-lines" id="co2-to" [control]="co2ToControl"
            placeholder="Select CO2 WLTP to" label="CO2 WLTP to"></app-input-number>

          <!-- country -->
          <app-dropdown class="mb-6" id="country-filter" [options]="countries" [control]="countryControl"
            [resetSelection]="true" (emitOnChangeSelect)="selectCountry($event)" label="Country"
            placeholder="Select country"></app-dropdown>
        </div>
      </div>
    </app-dropdown-panel>

    <app-white-btn [variant]="2" (clickEmitter)="moreFiltersPanel.togglePanel()">
      {{moreFiltersPanel.expandedPanel ? 'Show less filters' : 'Show more filters'}}
    </app-white-btn>
  </div>
</ng-template>

<ng-template #placeholderCars>
  <div class="col-span-1 md:col-span-2 xl:col-span-3 flex flex-col gap-6">
    <app-car-shop-card-placeholder *ngFor="let i of [].constructor(5)"></app-car-shop-card-placeholder>
  </div>
</ng-template>

<ng-template #noResults>
  <div class="flex justify-center col-span-1 md:col-span-2 xl:col-span-3 bg-white p-6">
    <div class="grid grid-cols-1 xl:grid-cols-3 gap-6 items-center">
      <div class="hidden xl:block rounded w-full max-h-40 bg-gradient-to-l from-white-hoverGrey to-white aspect-video">
      </div>

      <div
        class="m-auto max-w-xs xl:max-w-none xl:m-0 rounded w-full max-h-52 h-96 bg-white-hoverGrey aspect-video text-white flex justify-center items-center">
        <fa-icon class="px-4 py-3 bg-white-disabled" [icon]="['fas','cars']"></fa-icon>
      </div>

      <div class="hidden xl:block rounded w-full max-h-40 bg-gradient-to-r from-white-hoverGrey to-white aspect-video">
      </div>

      <div class="col-span-1 xl:col-start-2 text-center">
        <p class="text-2xl">No results found!</p>
        <p class="mt-6 mb-20">Your filters criterias are producing 0 results. Try other filters or reset filters.</p>

        <app-blue-btn id="no-results-clear-filters" class="m-auto block max-w-[13.75rem]"
          (clickEmitter)="clearFilters()" [orangeBtn]="isClosedSales">Reset filters</app-blue-btn>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #filtersPlaceholder>
  <div class="col-span-1 h-min w-full flex flex-col gap-3">
    <div *ngFor="let i of [].constructor(10)" class="bg-white px-9 py-6 rounded flex flex-col gap-3">
      <div class="placeholder-animation w-full h-6" *ngFor="let i of [].constructor(2)"></div>
    </div>
  </div>
</ng-template>
