<app-my-profile-page-layout [profilePage]="'orders'">
  <div class="flex flex-col gap-6" *ngIf="!(loading | async);else placeholder">
    <app-main-content-layout>
      <div class="flex flex-wrap gap-4 justify-between mb-8">
        <div class="flex items-center gap-3">
          <fa-icon (click)="goBack()" class="fa-icon-div text-blue-ctaBlue cursor-pointer"
            [icon]="['far','arrow-left']"></fa-icon>
          <p class="break-all">Order number {{order!.orderId}}</p>
        </div>
      </div>

      <div class="flex flex-col gap-6 sm:flex-row sm:justify-between">
        <div class="flex flex-col sm:flex-row gap-6 xl:gap-12 flex-wrap">
          <div class="flex gap-4">
            <fa-icon class="fa-icon-div text-blue-ctaBlue" [icon]="['fas','credit-card']"></fa-icon>
            <div>
              <p class="font-bold text-blue-ctaBlue mb-2">€{{order!.totalInvoiced | number}}</p>
              <p class="text-sm text-white-greyText max-w-xs">Total amount</p>
            </div>
          </div>

          <div class="flex gap-4">
            <fa-icon class="fa-icon-div text-black" [icon]="['fas','credit-card']"></fa-icon>
            <div>
              <p class="font-bold text-black mb-2">€{{getTotalExtras() | number}}</p>
              <p class="text-sm text-white-greyText">Amount of extras fees, out of total order amount.</p>
            </div>
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div class="flex gap-4">
              <fa-icon class="fa-icon-div text-blue-ctaBlue" [icon]="['fas','circle-user']"></fa-icon>
              <div>
                <p class="mb-2">{{order!.kam.firstName}} {{order!.kam.lastName}}</p>

                <p class="mb-2 sm:hidden">{{order!.kam.username}}</p>

                <p class="mb-2  sm:hidden">{{order!.kam.phoneNumber}}</p>

                <p class="text-sm text-white-greyText">Your Key Account Manager</p>
              </div>
            </div>

            <div class="pl-6 border-l border-white-lines hidden sm:block">
              <p class="mb-2">{{order!.kam.username}}</p>

              <p>{{order!.kam.phoneNumber}}</p>
            </div>
          </div>
        </div>
      </div>
    </app-main-content-layout>

    <app-main-content-layout>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-9 gap-y-12">
        <div class="sm:pr-9 sm:border-r border-white-lines">
          <div class="">
            <p class="font-bold">Billing Address</p>
            <p class="mt-6 mb-2">
              {{order!.buyer.country.location}}, {{order!.buyer.city}},
              {{order!.buyer.address}},
              {{order!.buyer.postalCode}}
            </p>

            <p class="text-sm text-white-greyText">{{order!.createdBy.username}}</p>
          </div>

          <div class="mt-12">
            <p class="font-bold">Order options</p>

            <div class="flex gap-x-6 gap-y-3 flex-wrap">
              <app-checkbox componentId="separate-transport" label="Separate invoice for transport"
                [checkboxValue]="order!.separateInvoiceForTransport" [checkboxDisabled]="true"></app-checkbox>
              <app-checkbox componentId="separate-invoice-car" label="One invoice per car"
                [checkboxValue]="order!.oneInvoicePerCar" [checkboxDisabled]="true"></app-checkbox>
            </div>
          </div>
        </div>

        <div>
          <div>
            <p class="font-bold">Delivery method</p>
            <p class="mt-6 mb-2">
              Transport by Cars2click: {{order!.transportDetails.transportByC2C ? 'Yes' : 'No'}}
            </p>
          </div>

          <div class="mt-12" *ngIf="order!.documents && order!.documents.length > 0">
            <p class="font-bold">Order documents</p>

            <div class="flex flex-col gap-2 mt-6">
              <p class="underline text-blue-blueTeal cursor-pointer text-sm" *ngFor="let d of order!.documents"
                (click)="downloadOrderDoc(d.id)">{{d.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </app-main-content-layout>


    <div class="flex flex-col gap-6">
      <!-- cars confimred cards -->
      <div *ngIf="carsConfirmed.length > 0"
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        <p class="font-bold mb-6 col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5">Confirmed cars</p>

        <div *ngFor="let car of carsConfirmed">
          <ng-container *ngTemplateOutlet="carCard; context: { $implicit: car}"></ng-container>
        </div>
      </div>

      <!-- cars unset cards -->
      <div *ngIf="carsUnset.length > 0"
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        <p class="font-bold mb-6 col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5">Pending cars</p>

        <div *ngFor="let car of carsUnset">
          <ng-container *ngTemplateOutlet="carCard; context: { $implicit: car}"></ng-container>
        </div>
      </div>

      <!-- cars cancelled cards -->
      <div *ngIf="order!.removedCars.length > 0"
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        <p class="font-bold mb-6 col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5">Cancelled cars</p>

        <div *ngFor="let car of order!.removedCars">
          <ng-container *ngTemplateOutlet="cancelledCarCard; context: { $implicit: car}"></ng-container>
        </div>
      </div>
    </div>
  </div>
</app-my-profile-page-layout>

<ng-template #placeholder>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
    <app-car-card-placeholder *ngFor="let i of [].constructor(10)"></app-car-card-placeholder>
  </div>
</ng-template>

<ng-template #noDoc>
  <fa-icon class="text-red" [icon]="['fas','xmark-circle']"></fa-icon>
</ng-template>


<ng-template #carCard let-car>
  <div class="h-full bg-white">
    <div class="flex w-full h-60 xl:h-[calc(100vw*0.1)] mr-9">
      <img class="w-full object-cover rounded"
        [src]="car!.carConfig.profilePhoto && car!.carConfig.profilePhoto != 'None' ? car!.carConfig.profilePhoto :  './assets/photos/car-placeholder.png'"
        alt="">
    </div>

    <div class="p-6 bg-white flex flex-col gap-6">
      <div class="flex flex-col gap-3 pb-4 border-b border-white-lines">
        <p class="font-bold">{{car!.carConfig.make}} {{car!.carConfig.model}}, {{car!.carConfig.variant}}</p>
        <p class="text-xs text-white-700">VIN: {{car.carConfig.vin}}</p>
        <p class="font-bold text-blue-ctaBlue">€{{car!.carPricing.invoiceSellingPrice| number}}</p>
      </div>

      <div class="flex flex-col gap-3">
        <div class="flex justify-between gap-3 text-xs py-2 px-3 bg-white-bgGrey w-full">
          <div class="flex gap-3">
            <fa-icon *ngIf="car!.documentsDetails.documents.registration.documents.length > 0;else noDoc"
              class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
            <p>Registration doc</p>
          </div>

          <fa-icon
            (click)="downloadFile(car!.carMainInfoId, car!.documentsDetails.documents.registration.documents[0].id,'REG')"
            class="cursor-pointer" *ngIf="car!.documentsDetails.documents.registration.documents.length > 0"
            [icon]="['fal','arrow-down-to-line']"></fa-icon>
        </div>

        <div class="flex justify-between gap-3 text-xs py-2 px-3 bg-white-bgGrey w-full">
          <div class="flex gap-3">
            <fa-icon *ngIf="car!.documentsDetails.documents.invoicing.documents.length > 0;else noDoc"
              class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
            <p>Car invoice</p>
          </div>

          <fa-icon class="cursor-pointer" *ngIf="car!.documentsDetails.documents.invoicing.documents.length > 0"
            (click)="downloadFile(car!.carMainInfoId, car!.documentsDetails.documents.invoicing.documents[0].id,'INV')"
            [icon]="['fal','arrow-down-to-line']"></fa-icon>
        </div>

        <div class="flex justify-between gap-3 text-xs py-2 px-3 bg-white-bgGrey w-full"
          *ngIf="car!.documentsDetails.documents.coc.required">
          <div class="flex gap-3">
            <fa-icon *ngIf="car!.documentsDetails.documents.coc.documents.length > 0;else noDoc"
              class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
            <p>COC</p>
          </div>

          <fa-icon class="cursor-pointer" *ngIf="car!.documentsDetails.documents.coc.documents.length > 0"
            (click)="downloadFile(car!.carMainInfoId, car!.documentsDetails.documents.coc.documents[0].id,'COC')"
            [icon]="['fal','arrow-down-to-line']"></fa-icon>
        </div>

        <div class="flex justify-between gap-3 text-xs py-2 px-3 bg-white-bgGrey w-full"
          *ngIf="car!.documentsDetails.documents.cc.documents.length > 0">
          <div class="flex gap-3">
            <fa-icon class="text-blue-ctaBlue" [icon]="['fas','circle-check']"></fa-icon>
            <p>CC</p>
          </div>

          <fa-icon class="cursor-pointer"
            (click)="downloadFile(car!.carMainInfoId, car!.documentsDetails.documents.cc.documents[0].id,'CC')"
            [icon]="['fal','arrow-down-to-line']"></fa-icon>
        </div>
      </div>

      <div class="flex flex-col gap-1 text-sm text-white-greyText pt-4 border-t border-white-lines"
        *ngIf="car.transportDetails">
        <p>Transport order number: {{car.transportDetails.transportNumber}}</p>
        <p>Transport status: {{car.transportDetails.status}}</p>
        <p>{{car.transportDetails.actualDeliveryDate ? 'Actual delivery date:' : 'Estimated delivery:'}}
          {{car.transportDetails.actualDeliveryDate ? (car.transportDetails.actualDeliveryDate | date: 'yyyy-MM-dd') :
          (car.transportDetails.estimatedDeliveryDate | date: 'yyyy-MM-dd')}}
        </p>
        <p>Pick up date: {{car.transportDetails.pickupDate | date: 'yyyy-MM-dd'}}</p>
      </div>


      <app-green-btn (clickEmitter)="openViewCarModal(car!.carMainInfoId)">View car</app-green-btn>

      <div>
        <div class="text-center cursor-pointer select-none py-2 text-sm">
          <p (click)="detailsPanel.togglePanel()">Show {{detailsPanel.expandedPanel ? 'less' : 'more'}} details</p>
        </div>

        <app-dropdown-panel #detailsPanel [panelId]="'order-car-card-details-' + car!.carMainInfoId">
          <div class="mt-4 pt-4 border-t border-white-lines">
            <div class="flex flex-col gap-4 text-sm text-white-greyText">
              <app-checkbox [checkboxDisabled]="true" [componentId]="'car-coc' + car!.carMainInfoId"
                label="COC Required" [checkboxValue]="car!.documentsDetails.documents.coc.required"></app-checkbox>

              <app-checkbox [checkboxDisabled]="true" [componentId]="'car-photoEdit' + car!.carMainInfoId"
                [checkboxValue]="car!.photoEditing.photoEdit" label="Photo edit"></app-checkbox>

              <p class="breakWords">
                Payment status: <span [ngClass]="order!.buyer.buyerPaid === 'True' ? 'paid-box' : 'not-paid-box' ">
                  {{order!.buyer.buyerPaid === 'True' ? 'Paid' : 'Not paid'}}</span>
              </p>

              <p class="breakWords">Payment date: {{(order!.buyer.buyerPaidAt ? order!.buyer.buyerPaidAt :
                order?.estimatedPaymentDate) | date: 'yyyy-MM-dd'}}</p>
            </div>
          </div>
        </app-dropdown-panel>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #cancelledCarCard let-car>
  <div class="h-full bg-white opacity-">
    <div class="flex w-full h-60 xl:h-[calc(100vw*0.1)] mr-9 opacity-40">
      <img class="w-full object-cover rounded" [src]="car!.profilePhoto" alt="">
    </div>

    <div class="p-6 bg-white flex flex-col gap-6 text-white-greyText">
      <div class="flex flex-col gap-3">
        <p class="font-bold">{{car!.make}} {{car!.model}}, {{car!.variant}}</p>
        <p>Cancelled</p>
        <p *ngIf="car.vin" class="text-xs">VIN: {{car.vin}}</p>
        <p class="font-bold">€{{car!.invoiceSellingPrice| number}}</p>
      </div>
    </div>
  </div>
</ng-template>
