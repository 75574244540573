import { NgModule } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";

@NgModule({
  declarations: [
  ],
  imports: [
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    ClipboardModule,
    MatDialogModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatMenuModule,
    MatTableModule
  ],
  exports: [
    MatSelectModule,
    MatIconModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatCheckboxModule,
    ClipboardModule,
    MatDialogModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatMenuModule,
    MatTableModule
  ]
})

export class AppMaterialModule { }
