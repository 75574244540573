import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ImageFullScreenComponent } from './image-full-screen/image-full-screen.component';
import { CarPhoto } from 'src/app/core/models/car.model';

@Component({
  selector: 'app-image-slider',
  templateUrl: './app-image-slider.component.html',
  styleUrls: ['./app-image-slider.component.scss']
})
export class AppImageSliderComponent implements OnInit {
  @Input() images: CarPhoto[] = [];

  public isMobile: boolean = false;
  public selectedImage: string = '';

  imageObject: { image: string, thumbImage: string, alt: string }[] = [];

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.imageObject = this.images.map(i => ({
      image: i.originalPhoto!,
      thumbImage: i.originalPhoto!,
      alt: 'Car photo',
    }));

    if (this.images.length > 0) {
      this.selectedImage = this.imageObject[0].image;
    }

    this.isMobile = window.innerWidth < 1024;

    window.onresize = () => this.isMobile = window.innerWidth < 1024;
  }

  selectImage(index: number) {
    this.selectedImage = this.imageObject[index].image;
  }

  openFullScreenPhoto() {
    const dialogRef = this.dialog.open(
      ImageFullScreenComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        photos: this.images.map(i => i.originalPhoto)
      },
    });
  }
}
